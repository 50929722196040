var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"1024","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary","dense":"","fixed":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeWin}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("User Profile "+_vm._s(_vm.lead.carbs))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.closeWin}},[_vm._v(" Save ")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Legal first name*","required":""},model:{value:(_vm.lead.unit),callback:function ($$v) {_vm.$set(_vm.lead, "unit", $$v)},expression:"lead.unit"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Legal middle name","hint":"example of helper text only on focus"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Legal last name*","hint":"example of persistent helper text","persistent-hint":"","required":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Email*","required":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Password*","type":"password","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":['0-17', '18-29', '30-54', '54+'],"label":"Age*","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":[
                'Skiing',
                'Ice hockey',
                'Soccer',
                'Basketball',
                'Hockey',
                'Reading',
                'Writing',
                'Coding',
                'Basejump',
              ],"label":"Interests","multiple":""}})],1)],1),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',[_c('v-card',{attrs:{"width":"400"}},[_c('v-card-text',[_c('div',{staticClass:"font-weight-bold ml-8 mb-2"},[_vm._v("Today")]),_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((_vm.messages),function(message){return _c('v-timeline-item',{key:message.time,attrs:{"color":message.color,"small":""}},[_c('div',[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(_vm._s(message.from))]),_vm._v(" @"+_vm._s(message.time)+" ")]),_c('div',[_vm._v(_vm._s(message.message))])])])}),1)],1)],1)],1),_c('v-col',[_vm._v(" test ")])],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue-darken-1","variant":"text"},on:{"click":_vm.closeWin}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue-darken-1","variant":"text"},on:{"click":_vm.closeWin}},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }