<template>
  <div>
    <WinLead :dialog="dialog" :lead="modalLead" v-on:closeWin="oncloseWin" />

    <v-data-table
      :headers="headers"
      :items="filteredDesserts"
      :items-per-page="15"
      class="elevation-1"
      @dblclick:row="editItem"
      fixed-header
      height="calc(100vh - 245px)"
    >
      <template v-slot:header.ref_number="{ header }">
        {{ header.text }}
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small :color="dessertName ? 'primary' : ''">
                mdi-filter
              </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field
              v-model="dessertName"
              class="pa-4"
              type="text"
              label="Enter the search term"
              :autofocus="true"
            ></v-text-field>
            <v-btn
              @click="dessertName = ''"
              small
              text
              color="primary"
              class="ml-2 mb-2"
              >Clean</v-btn
            >
          </div>
        </v-menu>
      </template>

      <template v-slot:header.unit="{ header }">
        {{ header.text }}
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small :color="calories ? 'primary' : ''">
                mdi-filter
              </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field
              v-model="calories"
              class="pa-4"
              type="number"
              label="Enter the search term"
              :autofocus="true"
            ></v-text-field>
            <v-btn
              @click="calories = ''"
              small
              text
              color="primary"
              class="ml-2 mb-2"
              >Clean</v-btn
            >
          </div>
        </v-menu>
      </template>

      <template v-slot:header.fat="{ header }">
        {{ header.text }}
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small :color="fat ? 'primary' : ''"> mdi-filter </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field
              v-model="fat"
              class="pa-4"
              type="number"
              label="Enter the search term"
              :autofocus="true"
            ></v-text-field>
            <v-btn
              @click="fat = ''"
              small
              text
              color="primary"
              class="ml-2 mb-2"
              >Clean</v-btn
            >
          </div>
        </v-menu>
      </template>

      <template v-slot:item.unit="{ value }">
        <v-chip :color="getColor(value)">
          {{ value }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import WinLead from "../components/WinLead.vue";

export default {
  components: {
    WinLead,
  },
  data: () => ({
    dialog: false,
    modalLead: {},
    conditions: [],
    dessertName: "",
    calories: "",
    fat: "",
    headers: [
      {
        text: "Reference Number",
        align: "start",
        sortable: true,
        value: "ref_number",
      },
      { text: "Unit", value: "unit" },
      { text: "Name", value: "fat" },
      { text: "Email", value: "carbs" },
      { text: "Mobile", value: "protein" },
      { text: "Campaign", value: "iron" },
    ],
    desserts: [
      {
        ref_number: "LEA-00001",
        unit: "SOLD",
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%",
      },
      {
        ref_number: "LEA-00002",
        unit: "NURTURE",
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%",
      },
      {
        ref_number: "LEA-00003",
        unit: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%",
      },
      {
        ref_number: "LEA-00004",
        unit: 269,
        fat: 19.0,
        carbs: 29,
        protein: 7.0,
        iron: "9%",
      },
      {
        ref_number: "LEA-00005",
        unit: 190,
        fat: 9.0,
        carbs: 19,
        protein: 2.0,
        iron: "3%",
      },
      {
        ref_number: "LEA-00006",
        unit: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: "8%",
      },
      {
        ref_number: "LEA-00007",
        unit: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: "16%",
      },
      {
        ref_number: "LEA-00007",
        unit: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: "0%",
      },
      {
        ref_number: "LEA-00007",
        unit: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: "2%",
      },
      {
        ref_number: "LEA-00007",
        unit: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: "45%",
      },
      {
        ref_number: "LEA-00007",
        unit: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: "22%",
      },
      {
        ref_number: "LEA-00007",
        unit: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%",
      },
    ],
  }),
  computed: {
    filteredDesserts() {
      var conditions = this.conditions;

      if (this.dessertName) {
        conditions.push(this.filterDessertName);
      }

      if (this.calories) {
        conditions.push(this.filterCalories);
      }

      if (this.fat) {
        conditions.push(this.filterFat);
      }

      if (conditions.length > 0) {
        return this.desserts.filter((dessert) => {
          return conditions.every((condition) => {
            return condition(dessert);
          });
        });
      }

      return this.desserts;
    },
  },
  methods: {
    oncloseWin(e) {
      this.dialog = false;
    },
    getColor(staus) {
      if (staus == "SOLD") return "green";
      else return "white";
    },
    editItem(event, { item }) {
      this.dialog = true;
      console.log(item);
      this.modalLead = item;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    filterDessertName(item) {
      return item.ref_number
        .toLowerCase()
        .includes(this.dessertName.toLowerCase());
    },
    filterCalories(item) {
      return item.unit.toString().includes(this.calories);
    },
    filterFat(item) {
      return item.fat.toString().includes(this.fat);
    },
  },
};
</script>
