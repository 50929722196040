<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="1024"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-toolbar dark color="primary" dense fixed>
        <v-btn icon dark @click="closeWin">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>User Profile {{ lead.carbs }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="closeWin"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Legal first name*"
                required
                v-model="lead.unit"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Legal middle name"
                hint="example of helper text only on focus"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Legal last name*"
                hint="example of persistent helper text"
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Email*" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Password*"
                type="password"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="['0-17', '18-29', '30-54', '54+']"
                label="Age*"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="[
                  'Skiing',
                  'Ice hockey',
                  'Soccer',
                  'Basketball',
                  'Hockey',
                  'Reading',
                  'Writing',
                  'Coding',
                  'Basejump',
                ]"
                label="Interests"
                multiple
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col>
              <v-card width="400">
                <v-card-text>
                  <div class="font-weight-bold ml-8 mb-2">Today</div>

                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="message in messages"
                      :key="message.time"
                      :color="message.color"
                      small
                    >
                      <div>
                        <div class="font-weight-normal">
                          <strong>{{ message.from }}</strong> @{{
                            message.time
                          }}
                        </div>
                        <div>{{ message.message }}</div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col> test </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeWin">
          Close
        </v-btn>
        <v-btn color="blue-darken-1" variant="text" @click="closeWin">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  methods: {
    closeWin() {
      this.$emit("closeWin");
    },
  },
  props: {
    dialog: Boolean,
    lead: Object,
  },
  data: () => ({
    messages: [
      {
        from: "You",
        message: `Deal close.`,
        time: "10:42am",
        color: "deep-purple lighten-1",
      },
      {
        from: "John Doe",
        message: "Chase client",
        time: "10:37am",
        color: "green",
      },
      {
        from: "You",
        message: "Lead source: Instagram",
        time: "9:47am",
        color: "deep-purple lighten-1",
      },
    ],
  }),
};
</script>
